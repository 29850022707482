import { RootState } from 'src/app/store';
import { Character, SignedUpCharacter } from './character';
import { Classes as WoWClasses } from './wowclasses';
import {
  LevelingOffer,
  LevelingSale,
  LFSType,
  LFGType,
  MegaDungeonOffer,
  MegaDungeonSale,
  MythicPlusOffer,
  MythicPlusSale,
  OfferObj,
  offerObjectType,
  PartyOffer,
  PartyOfferSale
} from './offer';
import { DiscordDetails, User } from './user';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';

export interface SignUpNotificationSettings {
  enable: boolean;
  sound: boolean;
  soundVolume: number;
  windowsNotif: boolean;
  filteredOnly: boolean;
  friendsOnly: boolean;
  keyholderOnly: boolean;
}

export interface SignUp {
  BoosterID: string;
  BoosterDetails?: DiscordDetails;
  TimeStamp: number;
  BoostsCompleted: number;
  Reviews: [number, number];
  BNet: string;
  Roles: string[];
  SortFactors: { [key: string]: number };
  RealmPaymentCharacters: Character[];
  AcceptedCharacter: SignedUpCharacter;
  ChosenPaymentCharacter: Character;
  AcceptedRole: string;
  StatusReport: boolean;
  ScamReport: boolean;
  Note: string;
  Premium: number;
  BonusTip: number;
  GoldTaken: boolean;
  GroupLeader: boolean;
  Type: string;
  _id: string;
  Hide?: boolean;
}

export interface PartySignUp extends SignUp {
  MainIO: number;
  Keys: string[];
  EligibleCharacters: SignedUpCharacter[];
  AcceptedKey: string;
}

export interface GroupSignUp {
  _id: string;
  SignUps: PartySignUp[];
  SortFactors: string;
  Roles: string[];
  Classes: string[];
  Keys: string[];
  PaymentRealms: string[];
  Answers: { [key: string]: boolean };
  AcceptedKeys: string[];
  Hide?: boolean;
}

export const SignUpCDs = [20, 5, 0, 0];

export function getPostedOffers() {
  const postedOffers = useSelector(
    (state: RootState) => state.offer.postedOffers
  );
  return postedOffers;
}

export function findPostedOfferById(id: string) {
  const postedOffers = getPostedOffers();
  return postedOffers[id];
}

function FilterEligibleCharacters(
  Characters: SignedUpCharacter[],
  Classes: string[],
  ArmorTypes: string[],
  Roles: string[],
  Keys: string[],
  KHOnly: boolean,
  faction?: 'Alliance' | 'Horde'
): SignedUpCharacter[] {
  if (Characters === undefined || Characters.length === 0) return [];
  let eligibleCharacters = [];
  for (let i = 0; i < Characters.length; i++) {
    if (
      (faction === undefined || faction === Characters[i].Faction) &&
      (Classes === undefined ||
        Classes.length === 0 ||
        Classes.includes(Characters[i].Class)) &&
      (ArmorTypes === undefined ||
        ArmorTypes.length === 0 ||
        ArmorTypes.includes(
          WoWClasses.find((wowclass) => wowclass.Name === Characters[i].Class)
            ?.Armor
        )) &&
      (KHOnly === undefined || !KHOnly || Characters[i].Key) &&
      (Keys === undefined ||
        Keys.length === 0 ||
        Keys.includes(Characters[i].Key))
    ) {
      // Check if the character has any of the roles in RoleIOs
      if (Roles && Roles.length > 0) {
        let RoleIOs = {};
        for (let j = 0; j < Roles.length; j++) {
          if (
            Roles[j] in Characters[i].RoleIOs &&
            Characters[i].RoleIOs[Roles[j]] > 0
          ) {
            RoleIOs[Roles[j]] = Characters[i].RoleIOs[Roles[j]];
          }
        }
        if (Object.keys(RoleIOs).length > 0) {
          eligibleCharacters.push({ ...Characters[i], RoleIOs: RoleIOs });
        }
      } else eligibleCharacters.push(Characters[i]);
    }
  }
  return eligibleCharacters;
}

export function IsGroupSignup(signup: GroupSignUp | PartySignUp) {
  return 'SignUps' in signup;
}

function GetSortFactor(signup: GroupSignUp | PartySignUp, SortFactor: string) {
  if (IsGroupSignup(signup)) {
    const GroupSignUp = signup as GroupSignUp;
    let Result = 0;
    for (let i = 0; i < GroupSignUp?.SignUps?.length; i++) {
      if (GroupSignUp?.SignUps[i]?.SortFactors?.[SortFactor] > Result) {
        Result = GroupSignUp?.SignUps[i]?.SortFactors?.[SortFactor];
      }
    }
    return Result;
  } else {
    const PartySignUp = signup as PartySignUp;
    return PartySignUp?.SortFactors[SortFactor];
  }
}

export class PostedOffer {
  Offer: offerObjectType;

  OfferHandler: OfferObj;

  SignUps: (PartySignUp | GroupSignUp)[];

  Boosters: PartySignUp[];

  PendingSignUps: PartySignUp[];

  Poster: User;

  constructor(
    offer: PartyOffer,
    SignUps: (PartySignUp | GroupSignUp)[],
    Boosters: PartySignUp[],
    Poster: User
  ) {
    this.Offer = offer;
    this.OfferHandler = new OfferObj(offer);
    this.Poster = Poster;
    this.SignUps = SignUps
      ? SignUps?.filter((signup) => {
          return Boosters?.every((booster) => {
            return booster._id !== signup._id;
          });
        })
      : [];
    this.Boosters = Boosters
      ? Boosters?.filter((booster) => {
          return SignUps?.every((signup) => {
            return signup._id !== booster._id;
          });
        })
      : [];
    this.PendingSignUps = Boosters
      ? Boosters.filter((booster) => {
          return this.Boosters?.every((signup) => {
            return signup._id !== booster._id;
          });
        })
      : [];
  }

  getSignUps(
    Classes?: string[],
    ArmorTypes?: string[],
    Roles?: string[],
    Keys?: string[],
    KHOnly?: boolean,
    faction?: 'Alliance' | 'Horde',
    SortFactor?: string,
    MinSortFactos?: { [key: string]: number },
    FriendsOnly?: boolean,
    Search?: string
  ): (PartySignUp | GroupSignUp)[] {
    let signUps: (PartySignUp | GroupSignUp)[] = [];
    const eligibleSignUps = this.SignUps.filter((signup) => {
      return Object.entries(MinSortFactos).every(([key, value]) => {
        if (GetSortFactor(signup, key) === undefined || !value) return true;
        return GetSortFactor(signup, key) >= value;
      });
    });

    for (let i = 0; i < eligibleSignUps.length; i++) {
      if (IsGroupSignup(eligibleSignUps[i])) {
        const GroupSignUp = eligibleSignUps[i] as GroupSignUp;
        if (FriendsOnly && this.Poster.Friends) {
          let NoFriend = true;
          for (let j = 0; j < GroupSignUp.SignUps.length; j++) {
            if (
              this.Poster.Friends.includes(GroupSignUp.SignUps[j].BoosterID)
            ) {
              NoFriend = false;
              break;
            }
          }
          if (NoFriend) continue;
        }
        let groupSignUps = [];
        for (let j = 0; j < GroupSignUp.SignUps.length; j++) {
          const SignUp = GroupSignUp.SignUps[j];
          const eligibleCharacters = FilterEligibleCharacters(
            SignUp.EligibleCharacters,
            Classes,
            ArmorTypes,
            Roles,
            Keys,
            KHOnly,
            faction
          );
          if (eligibleCharacters.length > 0) {
            groupSignUps.push({
              ...SignUp,
              EligibleCharacters: eligibleCharacters
            });
          }
        }
        if (groupSignUps.length > 0) {
          signUps.push({ ...GroupSignUp, SignUps: groupSignUps });
        }
      } else {
        const SignUp = eligibleSignUps[i] as PartySignUp;
        if (
          FriendsOnly &&
          this.Poster.Friends &&
          !this.Poster.Friends.includes(SignUp.BoosterID)
        ) {
          continue;
        }
        const eligibleCharacters = FilterEligibleCharacters(
          SignUp.EligibleCharacters,
          Classes,
          ArmorTypes,
          Roles,
          Keys,
          KHOnly,
          faction
        );
        if (eligibleCharacters.length > 0) {
          signUps.push({ ...SignUp, EligibleCharacters: eligibleCharacters });
        }
      }
      if (Search) {
        const singleOptions = {
          includeScore: true,
          threshold: 0.3,
          keys: [
            'BoosterDetails.UserName',
            'EligibleCharacters.Name',
            'EligibleCharacters.Realm',
            'SignUps.BoosterDetails.UserName',
            'SignUps.EligibleCharacters.Name',
            'SignUps.EligibleCharacters.Realm'
          ]
        };
        const groupOptions = {
          includeScore: true,
          threshold: 0.3,
          keys: [
            'SignUps.BoosterDetails.UserName',
            'SignUps.EligibleCharacters.Name',
            'SignUps.EligibleCharacters.Realm'
          ]
        };
        const singleFuse = new Fuse(signUps, singleOptions);
        const groupFuse = new Fuse(signUps, groupOptions);
        const results = [
          ...singleFuse.search(Search),
          ...groupFuse.search(Search)
        ];
        signUps = results.map((result) => result.item);
      }
    }

    if (SortFactor) {
      signUps?.sort((a, b) => {
        return GetSortFactor(b, SortFactor) - GetSortFactor(a, SortFactor);
      });
    }

    return signUps;
  }

  calculateCut(signup: PartySignUp) {
    if (!signup) return 0;
    let Cut = this.OfferHandler.CutPerBooster();
    if ('KeyBonusPayment' in this.Offer) {
      if (signup.AcceptedKey) {
        Cut += this.Offer.KeyBonusPayment;
      }
    }
    if ('RoleBonusPayment' in this.Offer) {
      if (signup.AcceptedRole) {
        Cut += this.Offer.RoleBonusPayment[signup.AcceptedRole] || 0;
      }
    }
    if ('GroupLeaderBonus' in this.Offer) {
      if (signup.GroupLeader) {
        Cut += this.Offer.GroupLeaderBonus;
      }
    }
    if (
      'CoordinatorBonusPercentage' in this.Offer &&
      'Coordinator' in this.Offer
    ) {
      if (signup.BoosterID === this.Offer.Coordinator) {
        Cut += this.OfferHandler.CoordinatorCut();
      }
    }
    return Cut + signup.BonusTip;
  }
}
