import { CharacterObj, SignedUpCharacter } from 'src/models/character';
import { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  Avatar,
  useTheme,
  Link,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  useMediaQuery,
  IconButton,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  AvatarGroup,
  Drawer,
  Accordion,
  Divider,
  ListItemButton,
  Badge
} from '@mui/material';
import { Classes, WoWRoleType } from 'src/models/wowclasses';
import { PartySignUp } from 'src/models/signup';
import Text from 'src/components/Text';
import { ReviewsString } from 'src/models/review';
import { Dungeons } from 'src/models/dungeons';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DiscordDetails, getBadgeColor, getUser } from 'src/models/user';
import ClickToCopy from 'src/components/Utility/CopiableText';
import ConfirmButton from 'src/components/Utility/ConfirmationButton';
import { Offer, offerDetails } from 'src/models/offer';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { getRealm } from 'src/models/realms';
import React from 'react';
import { SyntheticEvent } from 'react';
import {} from '@mui/material';
import { formatDistance, subMinutes } from 'date-fns';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import VideoCameraFrontTwoToneIcon from '@mui/icons-material/VideoCameraFrontTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ColorLensTwoToneIcon from '@mui/icons-material/ColorLensTwoTone';
import NotificationsOffTwoToneIcon from '@mui/icons-material/NotificationsOffTwoTone';
import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Message } from 'src/models/message';
import Messenger from 'src/content/applications/Messenger';
import { getUserMedals } from 'src/models/medals';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);

const ListItemIconWrapper = styled(ListItemIcon)(
  ({ theme }) => `
        min-width: 36px;
        color: ${theme.colors.primary.light};
`
);

const AccordionSummaryWrapper = styled(AccordionSummary)(
  ({ theme }) => `
        &.Mui-expanded {
          min-height: 48px;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0;
        }

        .MuiSvgIcon-root {
          transition: ${theme.transitions.create(['color'])};
        }

        &.MuiButtonBase-root {

          margin-bottom: ${theme.spacing(0.5)};

          &:last-child {
            margin-bottom: 0;
          }

          &.Mui-expanded,
          &:hover {
            background: ${theme.colors.alpha.black[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.main};
            }
          }
        }
`
);

export interface ProfilePopUpProps {
  id: string;
  discordDetails: DiscordDetails;
  onClose: () => void;
}

export const ProfilePopUp = ({
  id,
  discordDetails,
  onClose
}: ProfilePopUpProps) => {
  const dispatch: AppDispatch = useDispatch();
  const user = getUser();
  const onlineStatuses = useSelector(
    (state: RootState) => state.user?.onlineStatuses
  );
  const userMedals = getUserMedals(discordDetails?.Medals);
  const isLeader =
    user?.CurrentGroup?.Lead === user?._id ||
    user?.CurrentGroup?.Lead === undefined;
  const theme = useTheme();
  const [lastKnownDiscordDetails, setLastKnownDiscordDetails] =
    useState<DiscordDetails | null>(null);
  const [lastKnownid, setLastKnownid] = useState<string | null>(null);
  const karmaColor =
    lastKnownDiscordDetails?.Karma > 100
      ? 'success'
      : lastKnownDiscordDetails?.Karma < 0
      ? 'error'
      : 'warning';
  const isFriend = user?.Friends?.includes(lastKnownid);
  const isBlacklisted = Object.keys(user?.Blacklist || {})?.includes(
    lastKnownid
  );
  const isGroupMember = user?.CurrentGroup?.Members?.includes(lastKnownid);
  const [expanded, setExpanded] = useState<string | false>('section1');
  const handleChange =
    (section: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? section : false);
    };

  const onFriendClick = (discordID: string, add: boolean) => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'add_friend',
        data: {
          _id: discordID,
          add: add
        }
      }
    });
  };

  useEffect(() => {
    if (discordDetails) {
      setLastKnownDiscordDetails(discordDetails);
      setLastKnownid(id);
    }
  }, [discordDetails]);

  const onBlacklistAdd = (discordID: string, add: boolean) => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'add_blacklist',
        data: {
          _id: discordID,
          add: add
        }
      }
    });
  };

  return (
    <Drawer
      sx={{
        display: { xs: 'block', md: 'flex' }
      }}
      variant="temporary"
      anchor={theme.direction === 'rtl' ? 'left' : 'right'}
      open={discordDetails !== undefined}
      onClose={onClose}
      elevation={9}
    >
      <Box
        sx={{
          minWidth: 300,
          maxWidth: 500
        }}
        p={2}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent={''}
            color={getBadgeColor(onlineStatuses?.[lastKnownid])}
          >
            <Avatar
              sx={{
                mx: 'auto',
                my: 2,
                width: theme.spacing(12),
                height: theme.spacing(12)
              }}
              variant="rounded"
              alt={lastKnownDiscordDetails?.Username}
              src={lastKnownDiscordDetails?.AvatarURL}
            />
          </Badge>
          <Typography variant="h4">
            <ClickToCopy
              textToCopy={lastKnownDiscordDetails?.Username}
              sx={{
                alignItems: 'center',
                display: 'inline-flex',
                fontSize: 20
              }}
            >
              <Text color="warning">
                <b>{lastKnownDiscordDetails?.Username}</b>
              </Text>
              {isFriend && (
                <StarIcon color="warning" sx={{ width: 20, height: 20 }} />
              )}
            </ClickToCopy>
          </Typography>
          <Typography variant="subtitle1">
            <Text color={karmaColor}>
              {lastKnownDiscordDetails?.Karma} Karma
            </Text>
          </Typography>
          {/* <Typography variant="subtitle2">
              Active{' '}
              {formatDistance(subMinutes(new Date(), 7), new Date(), {
                addSuffix: true
              })}
            </Typography> */}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexWrap={'wrap'}
          >
            {userMedals?.map((medal) => (
              <Tooltip
                title={
                  <Box>
                    <Typography color={medal?.Color} variant="h4">
                      {medal?.Name}
                    </Typography>
                    <Typography variant="body2">
                      {medal?.Description}
                    </Typography>
                  </Box>
                }
                disableInteractive
                key={medal?.Short}
              >
                <Avatar
                  src={medal?.Image}
                  alt={medal?.Short}
                  sx={{ width: 100, height: 100, m: 0.5 }}
                />
              </Tooltip>
            ))}
          </Box>
        </Box>
        <Divider
          sx={{
            my: 3
          }}
        />
        <Box
          display={'flex'}
          justifyItems={'center'}
          order={{ xs: 2, md: 3 }}
          m={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {isFriend ? (
            <ConfirmButton
              confirmText="Remove Friend?"
              variant="outlined"
              color={'error'}
              sx={{ padding: '4px 8px', marginRight: 1, mb: 0.25 }}
              onClick={() => onFriendClick(id, false)}
            >
              Remove Friend
            </ConfirmButton>
          ) : (
            <Button
              variant="outlined"
              color="success"
              sx={{ padding: '4px 8px', marginRight: 1, mb: 0.25 }}
              onClick={() => onFriendClick(id, true)}
            >
              Add Friend
            </Button>
          )}
          {isBlacklisted ? (
            <ConfirmButton
              confirmText="Whitelist?"
              variant="outlined"
              color={'warning'}
              sx={{ padding: '4px 8px', marginRight: 1, mt: 0.25 }}
              onClick={() => onBlacklistAdd(id, false)}
            >
              Whitelist
            </ConfirmButton>
          ) : (
            <ConfirmButton
              variant="outlined"
              color="warning"
              sx={{ padding: '4px 8px', marginRight: 1, mt: 0.25 }}
              onClick={() => onBlacklistAdd(id, true)}
            >
              Blacklist
            </ConfirmButton>
          )}
        </Box>
        <Box
          display={'flex'}
          justifyItems={'center'}
          order={{ xs: 2, md: 3 }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {isLeader &&
            (isGroupMember ? (
              <React.Fragment>
                <ConfirmButton
                  confirmText="Invite to your team?"
                  variant="outlined"
                  color="error"
                  sx={{ padding: '4px 8px', marginRight: 1, mt: 0.25 }}
                  onClick={() =>
                    dispatch({
                      type: 'socket/Message/send',
                      payload: {
                        type: 'group_kick',
                        data: {
                          DiscordID: id
                        }
                      }
                    })
                  }
                >
                  Kick from Team
                </ConfirmButton>
                <ConfirmButton
                  confirmText="Invite to your team?"
                  variant="outlined"
                  color="info"
                  sx={{ padding: '4px 8px', marginRight: 1, mt: 0.25 }}
                  onClick={() =>
                    dispatch({
                      type: 'socket/Message/send',
                      payload: {
                        type: 'group_promote',
                        data: {
                          DiscordID: id
                        }
                      }
                    })
                  }
                >
                  Promote to Leader
                </ConfirmButton>
              </React.Fragment>
            ) : (
              <ConfirmButton
                confirmText="Invite to your team?"
                variant="outlined"
                color="success"
                sx={{ padding: '4px 8px', marginRight: 1, mt: 0.25 }}
                onClick={() =>
                  dispatch({
                    type: 'socket/Message/send',
                    payload: {
                      type: 'group_invite',
                      data: {
                        DiscordID: id
                      }
                    }
                  })
                }
              >
                Invite to Team
              </ConfirmButton>
            ))}
        </Box>
        {id && (
          <Card elevation={3} sx={{ my: 3 }}>
            <Messenger
              Messages={user.Messages?.Direct?.[id]}
              MessagesType="Direct"
              ID={id}
            />
          </Card>
        )}
        {/* 
        <Accordion
          expanded={expanded === 'section1'}
          onChange={handleChange('section1')}
        >
          <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Customize Chat</Typography>
          </AccordionSummaryWrapper>
          <AccordionDetails
            sx={{
              p: 0
            }}
          >
            <List component="nav">
              <ListItem button>
                <ListItemIconWrapper>
                  <SearchTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Search in Conversation"
                  primaryTypographyProps={{ variant: 'h5' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <ColorLensTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Change Theme Styling"
                  primaryTypographyProps={{ variant: 'h5' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <EmojiEmotionsTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Choose Default Emoji"
                  primaryTypographyProps={{ variant: 'h5' }}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'section2'}
          onChange={handleChange('section2')}
        >
          <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Privacy & Support</Typography>
          </AccordionSummaryWrapper>
          <AccordionDetails
            sx={{
              p: 0
            }}
          >
            <List component="nav">
              <ListItem button>
                <ListItemIconWrapper>
                  <NotificationsOffTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Turn off notifications"
                  primaryTypographyProps={{ variant: 'h5' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <CancelTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Ignore all messages"
                  primaryTypographyProps={{ variant: 'h5' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <BlockTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Block user"
                  primaryTypographyProps={{ variant: 'h5' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <WarningTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="Something's Wrong"
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary="Report the conversation and provide feedback"
                  secondaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'section3'}
          onChange={handleChange('section3')}
        >
          <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Shared Files</Typography>
          </AccordionSummaryWrapper>
          <AccordionDetails
            sx={{
              p: 0
            }}
          >
            <List component="nav">
              <ListItem button>
                <ListItemIconWrapper>
                  <DescriptionTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="HolidayPictures.zip"
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary="You opened in the past year"
                  secondaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <DescriptionTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="2021Screenshot.jpg"
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary="You edited this file yesterday"
                  secondaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </ListItem>
              <ListItem button>
                <ListItemIconWrapper>
                  <DescriptionTwoToneIcon />
                </ListItemIconWrapper>
                <ListItemText
                  primary="PresentationDeck.pdf"
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary="Never opened"
                  secondaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion> */}
      </Box>
    </Drawer>
  );
};
