import {
  Box,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { getUser } from 'src/models/user';
import Group from 'src/components/WoW/Group';
import MenuListWrapper from 'src/layouts/Styles/Header/Menu';

function HeaderMenu() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isGroupOpen, setIsGroupOpen] = useState<boolean>(false);
  const user = getUser();
  const limitedAccess = !(user?.Characters?.length > 0);
  const groupRef = useRef(null);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleGroupClick = (): void => {
    setIsGroupOpen(!isGroupOpen);
  };

  const handleGroupClose = (): void => {
    setIsGroupOpen(false);
  };

  return (
    <>
      <MenuListWrapper
        sx={{
          display: {
            xs: 'block',
            sm: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <Hidden mdDown>
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              component={NavLink}
              to="/dashboards/profile"
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Profile"
              />
            </ListItem>
            {!limitedAccess && (
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                button
                component={NavLink}
                to="/management/offers/offers"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Boost"
                />
              </ListItem>
            )}
            {!limitedAccess && (
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                button
                component={NavLink}
                to="/management/offers/buy"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Buy Boost"
                />
              </ListItem>
            )}
            {!limitedAccess && (
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                button
                component={NavLink}
                to="/dashboards/premium"
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary="Support Us"
                />
              </ListItem>
            )}
            {!limitedAccess && (
              <ListItem
                classes={{ root: 'MuiListItem-indicators' }}
                button
                ref={ref}
                onClick={handleOpen}
              >
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary={
                    <Box display="flex" alignItems="center">
                      Others
                      <Box display="flex" alignItems="center" pl={0.3}>
                        <ExpandMoreTwoToneIcon fontSize="small" />
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
            )}
          </Hidden>
          {/* {!limitedAccess && (
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              ref={groupRef}
              onClick={handleGroupClick}
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={
                  <Box display="flex" alignItems="center">
                    Manage Team
                    <Box display="flex" alignItems="center" pl={0.3}>
                      <ExpandMoreTwoToneIcon fontSize="small" />
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          )} */}
        </List>
      </MenuListWrapper>
      {limitedAccess && (
        <Typography variant="h4">Link your characters for access</Typography>
      )}
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/dashboards/premium">
          Premium
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/guides">
          Guides
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/dashboards/chats">
          Chats
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/shop">
          Your Products
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/profile/settings"
        >
          Settings
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/profile/notifications"
        >
          Notifications
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/profile/reviews"
        >
          Reviews
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/profile/appeals"
        >
          Appeals
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/dashboards/statistics"
        >
          Statistics
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/offers/leaderboards"
        >
          Leaderboards
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/offers/karma"
        >
          Karma
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={groupRef.current}
        onClose={handleGroupClose}
        open={isGroupOpen}
      >
        <Group />
      </Menu>
    </>
  );
}

export default HeaderMenu;
