import { Box, Grid, Typography, TypographyProps } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import { Classes } from 'src/models/wowclasses';
import { getRealm } from 'src/models/realms';
import { Character } from 'src/models/character';

export interface SignedupCharactersProps
  extends Omit<TypographyProps, 'color'> {
  signedUpCharacters: Character[];
}
export const SignedupCharacters = ({
  signedUpCharacters,
  ...typographyProps
}: SignedupCharactersProps) => {
  return (
    <Box display={'flex'}>
      {signedUpCharacters?.length > 0 ? (
        <Grid container spacing={0.5}>
          {signedUpCharacters?.map((character, index) => {
            const charClass = Classes.find(
              (wowclass) => wowclass.Name === character.Class
            );
            return (
              <Grid
                item
                sm={12}
                key={index}
                display={'inline-flex'}
                alignItems={'center'}
              >
                <EmojiAvatar src={charClass?.Emoji} />
                <Typography {...typographyProps} color={charClass.Color}>
                  {character.Name}-{getRealm(character.Server, character.Realm)}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        'No SignedUp Chars'
      )}
    </Box>
  );
};

export default SignedupCharacters;
