import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import {
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Tooltip,
  useTheme
} from '@mui/material';
import { Box, Typography, Grid } from '@mui/material';
import { LFSType, offerObjectType, SaleOfferObj } from 'src/models/offer';
import Text from 'src/components/Text';
import { useEffect, useState } from 'react';
import { getUser } from 'src/models/user';
import { Character } from 'src/models/character';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import React from 'react';
import { averageStats } from 'src/models/averageStats';
import Title from './Title';
import Cut from './Cut';
import PaymentRealm from './PaymentRealm';
import OpenSpots from './OpenSpots';
import Creditibility from './Credibility';
import CurrentGroup from './CurrentGroup';
import BuyerParticipation from './BuyerParticipation';
import SignedupCharacters from './SignedupCharacters';
import EligibleClasses from './EligibleClasses';
import KeyList from './KeyList';
import PosterAvatar from './PosterAvatar';
import Details from './Details';
import { FindDetailsFields } from 'src/models/offerDetails/parseFields';
import { FindRank } from 'src/models/ranks';
import Karma from './Karma';
import OfferEmoji from './OfferEmoji';
import { RaidBosses } from 'src/models/raidbosses';
import BuyerPrice from './BuyerPrice';
import ArmorSpots from './ArmorSpots';
import TokenSpots from './TokenSpots';

const getStatusColor = (
  status: string
): 'error' | 'success' | 'warning' | 'info' => {
  const map = {
    Failed: 'error',
    Completed: 'success',
    Paid: 'success',
    SelfRun: 'warning',
    Pending: 'info',
    SignedUp: 'success',
    Canceled: 'error',
    Ongoing: 'info'
  };

  return map[status];
};

export interface OffersProps {
  offer?: offerObjectType;
  preFilledStats?: averageStats;
  defaultExpanded?: boolean;
  isLFS?: boolean;
}

export const Offers = ({
  offer = {},
  preFilledStats,
  defaultExpanded,
  isLFS = true
}: OffersProps) => {
  const theme = useTheme();
  const offerHandler = new SaleOfferObj(offer);
  const [DetailsFields, RequirementsFields] = FindDetailsFields(
    offer?.OfferType
  );
  const user = getUser();
  const stats = preFilledStats
    ? preFilledStats
    : useSelector((state: RootState) => state.averageStats.Data);
  const [expanded, setExpanded] = useState(defaultExpanded || false);

  const signedUpCharacters: Character[] =
    offer?.Status === 'Pending'
      ? user?.Characters?.filter((character) =>
          offer?.SignedUpCharacters?.includes(character._id)
        ) ?? []
      : [];

  const selfRun = user?._id === offer?.PosterID;

  const singedUp =
    offer?.Status == 'Pending' &&
    offer?.SignedUpCharacters &&
    offer?.SignedUpCharacters?.length > 0;

  const posterKarma = offer?.PosterDetails?.Karma || 0;
  const karmaColor =
    posterKarma > 100 ? 'success' : posterKarma < -100 ? 'error' : 'info';

  const CurrentBuyers =
    'Buyers' in offer
      ? Object.values(offer.Buyers).reduce((acc, Buyer) => {
          Buyer.Characters.forEach((Character) => {
            Character.Items.forEach((Item) => {
              acc[Item._id] = (acc[Item._id] || 0) + 1;
            });
          });
          return acc;
        }, {} as Record<string, number>)
      : {};

  const textColor =
    offer.Status == 'Pending'
      ? karmaColor
      : getStatusColor(
          selfRun ? 'SelfRun' : singedUp ? 'SignedUp' : offer.Status
        );

  useEffect(() => {
    setExpanded(defaultExpanded || false);
  }, [defaultExpanded]);

  return (
    <React.Fragment>
      <Card
        sx={{
          border: 'none',
          boxShadow: 'none'
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          padding={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <PosterAvatar
            CreditibilityIndex={offerHandler.CreditibilityIndex()}
            PosterID={offer.PosterID}
            posterDiscordDetails={offer.PosterDetails}
            PosterReviewString={offerHandler.ReviewsString()}
            rank={FindRank(offer.PosterPoints, 'Advertising')?.Rank}
            medals={offer.PosterDetails?.Medals}
            mainMedal={offer.PosterDetails?.MainMedal}
            variant="body2"
          />
          <CardActionArea onClick={() => setExpanded(!expanded)}>
            <Tooltip
              title={
                <CurrentGroup
                  currentGroup={offer.CurrentGroup}
                  Metrics={
                    offer.OfferType === 'Raid'
                      ? {
                          RaidProg: [
                            '',
                            '/' + (RaidBosses.length - 1).toString() + 'M'
                          ],
                          RaidParse: ['%', '']
                        }
                      : { IO: ['', 'io'], ILVL: ['', 'ilvl'] }
                  }
                  variant="h5"
                />
              }
              disableInteractive
              placement="top-start"
              sx={{
                alignItems: 'center'
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={isLFS ? 7.5 : 3.5}
                  textAlign={{ xs: 'center', sm: 'left' }}
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    textAlign={{ xs: 'center', sm: 'left' }}
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  >
                    <OfferEmoji
                      PosterID={offer.PosterID}
                      UserID={user._id}
                      OfferType={offer.OfferType}
                    />
                    <Box>
                      {
                        <Title
                          OfferDetails={
                            isLFS && 'Title' in offer
                              ? offer.Title
                              : offerHandler.Details()
                          }
                          TextColor={textColor}
                          variant="h4"
                          gutterBottom
                        />
                      }
                      {!isLFS && (
                        <Box>
                          <Karma
                            KarmaGain={
                              (user._id === offer.PosterID
                                ? offer.Karma
                                : offer.BoosterKarma) || 0
                            }
                            variant="h5"
                            gutterBottom
                          />
                        </Box>
                      )}
                      {isLFS && (
                        <Box>
                          <PaymentRealm
                            PaymentRealms={[offer.Realm]}
                            PaymentFactions={offer.PaymentFactions}
                            variant="h4"
                            gutterBottom
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4.4}
                  textAlign={{ xs: 'center', sm: 'left' }}
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                >
                  {!isLFS && (
                    <Cut
                      MarketAverageIndex={offerHandler.MarketAverageIndex(
                        stats
                      )}
                      CutPerBoosterString={offerHandler.CutPerBoosterString()}
                      KeyBonusPayment={
                        'KeyBonusPayment' in offer
                          ? offer.KeyBonusPayment
                          : undefined
                      }
                      CoordinatorBonusString={offerHandler.CoordinatorCutString()}
                      GroupLeaderBonus={
                        'GroupLeaderBonus' in offer
                          ? offer.GroupLeaderBonus
                          : undefined
                      }
                      RoleBonusPayment={offerHandler.RoleBonusPayment()}
                      variant="h4"
                      gutterBottom
                    />
                  )}
                  {isLFS && 'Buyers' in offer && (
                    <Box>
                      <BuyerPrice
                        items={offer.PriceList}
                        currentBuyers={CurrentBuyers}
                      />
                    </Box>
                  )}
                </Grid>
                {!isLFS && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    textAlign={{ xs: 'center', sm: 'left' }}
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    pl={{ xs: 0, sm: 4, md: 0 }}
                  >
                    <PaymentRealm
                      PaymentRealms={[offer.Realm]}
                      PaymentFactions={offer.PaymentFactions}
                      variant="h4"
                      gutterBottom
                    />
                    {offer.Status === 'Pending' && (
                      <Typography variant="h5" gutterBottom>
                        <Text color="warning">
                          {offer.TotalSignUps || 0} Signups{' '}
                        </Text>
                      </Typography>
                    )}
                  </Grid>
                )}
                {!isLFS && offer.Status === 'Pending' && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    textAlign={{ xs: 'center', sm: 'left' }}
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={{ xs: 'center', sm: 'flex-start' }}
                    >
                      <Typography variant="h5" mr={1}>
                        LF:
                      </Typography>
                      <OpenSpots
                        openSpots={offerHandler.OpenSpots()}
                        totalSpots={offer.TotalSpots}
                        keyholder={
                          offerHandler.RemainingKeys().length > 0 ||
                          ('BuyerKey' in offer &&
                            !offer.BuyerKey &&
                            offer.CurrentGroup.length === 0)
                        }
                      />
                    </Box>
                    <Box mt={0.5}>
                      <CurrentGroup
                        currentGroup={offer.CurrentGroup}
                        Metrics={
                          offer.OfferType === 'Raid'
                            ? {
                                RaidProg: [
                                  '',
                                  '/' + (RaidBosses.length - 1).toString() + 'M'
                                ],
                                RaidParse: ['%', '']
                              }
                            : { IO: ['', 'io'], ILVL: ['', 'ilvl'] }
                        }
                        iconsOnly
                        variant="h5"
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Tooltip>
          </CardActionArea>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              container
              spacing={2}
              padding={2}
              borderRadius={3}
              marginBottom={1}
              sx={{
                backgroundImage: theme.colors.gradients.blue3
              }}
            >
              {!isLFS && (
                <Grid item xs={12} sm={4} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Credibility:</Text>
                  </Typography>
                  <Creditibility
                    index={offerHandler.CreditibilityIndex()}
                    reviewString={offerHandler.ReviewsString()}
                    discordDetails={offer.PosterDetails}
                    variant="body2"
                  />
                </Grid>
              )}

              {!isLFS && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Details:</Text>
                  </Typography>
                  <Details offer={offer} fields={DetailsFields} />
                </Grid>
              )}
              {!isLFS && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Cut:</Text>
                  </Typography>
                  <Cut
                    MarketAverageIndex={offerHandler.MarketAverageIndex(stats)}
                    CutPerBoosterString={offerHandler.CutPerBoosterString()}
                    KeyBonusPayment={
                      'KeyBonusPayment' in offer
                        ? offer.KeyBonusPayment
                        : undefined
                    }
                    CoordinatorBonusString={offerHandler.CoordinatorCutString()}
                    GroupLeaderBonus={
                      'GroupLeaderBonus' in offer
                        ? offer.GroupLeaderBonus
                        : undefined
                    }
                    Detailed
                    RoleBonusPayment={offerHandler.RoleBonusPayment()}
                  />
                </Grid>
              )}
              {!isLFS && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Requirements:</Text>
                  </Typography>
                  <Details offer={offer} fields={RequirementsFields} />
                </Grid>
              )}
              {!isLFS && 'Keys' in offer && offer.Keys !== undefined && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Keys: </Text>
                  </Typography>
                  <KeyList
                    allkeys={offerHandler.AllKeys()}
                    remainingKeys={offerHandler.RemainingKeys()}
                    foundKeys={offerHandler.CurrentKeys()}
                    buyerKey={'BuyerKey' in offer && offer?.BuyerKey}
                    variant="body2"
                  />
                </Grid>
              )}
              {!isLFS &&
                offerHandler.OpenSpots() !== undefined &&
                offer.TotalSpots !== undefined && (
                  <Grid item sm={6} md={3}>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">Filling Progress: </Text>
                    </Typography>
                    <OpenSpots
                      openSpots={offerHandler.OpenSpots()}
                      totalSpots={offer.TotalSpots}
                      keyholder={
                        offerHandler.RemainingKeys().length > 0 ||
                        ('BuyerKey' in offer &&
                          !offer.BuyerKey &&
                          offer.CurrentGroup.length === 0)
                      }
                      Detailed
                      variant="body2"
                    />
                  </Grid>
                )}

              {/* {isLFS &&
                'ArmorTraders' in offer &&
                offer.ArmorTraders !== undefined && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">Armor Traders: </Text>
                    </Typography>
                    <ArmorSpots
                      takenArmorSpots={offerHandler.TakenArmorSpots()}
                      totalArmorSpots={offer.ArmorTraders}
                      variant="body2"
                    />
                  </Grid>
                )} */}
              {/* {isLFS &&
                'TokenTraders' in offer &&
                offer.TokenTraders !== undefined && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">Tier Traders: </Text>
                    </Typography>
                    <TokenSpots
                      takenTierSpots={offerHandler.TakenTokensSpots()}
                      totalTierSpots={offer.TokenTraders}
                      variant="body2"
                    />
                  </Grid>
                )} */}
              {offerHandler.TotalBooster() > 0 &&
                offer.CurrentGroup !== undefined && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">Current Group: </Text>
                    </Typography>
                    <CurrentGroup
                      currentGroup={offer.CurrentGroup}
                      Metrics={
                        offer.OfferType === 'Raid'
                          ? {
                              RaidProg: [
                                '',
                                '/' + (RaidBosses.length - 1).toString() + 'M'
                              ],
                              RaidParse: ['%', '']
                            }
                          : { IO: ['', 'io'], ILVL: ['', 'ilvl'] }
                      }
                      variant="h5"
                    />
                  </Grid>
                )}
              {isLFS &&
                'Buyers' in offer &&
                offer.PriceList.map((item) => (
                  <Grid item xs={12} md={6} key={item.Title}>
                    <Typography variant="h4" gutterBottom marginRight={0.5}>
                      <Text color="black">{item.Title}</Text>
                    </Typography>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">
                        {item.Category} {item.SubCategory}
                      </Text>
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ whiteSpace: 'pre-line' }}
                      fontSize={14}
                    >
                      <Text color="secondary">{item.Description}</Text>
                    </Typography>
                    <BuyerPrice
                      items={[item]}
                      currentBuyers={CurrentBuyers}
                      Detailed
                    />
                  </Grid>
                ))}
              {/* {offer.PaymentRealms !== undefined && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Payment Realms:</Text>
                  </Typography>
                  <PaymentRealm
                    PaymentRealms={offer.PaymentRealms}
                    PaymentFactions={offer.PaymentFactions}
                    variant="body2"
                    gutterBottom
                  />
                </Grid>
              )} */}

              {!isLFS &&
                'BuyerParticipation' in offer &&
                offer.BuyerParticipation !== undefined && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">Buyer Info:</Text>
                    </Typography>

                    <BuyerParticipation
                      participates={offer.BuyerParticipation}
                      variant="body2"
                      gutterBottom
                    />
                  </Grid>
                )}

              {!isLFS && 'Karma' in offer && offer.Karma !== undefined && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Karma:</Text>
                  </Typography>
                  <Karma
                    KarmaGain={
                      (user._id === offer.PosterID
                        ? offer.Karma
                        : offer.BoosterKarma) || 0
                    }
                    variant="body2"
                    gutterBottom
                  />
                </Grid>
              )}

              {!isLFS && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">SignedUp Chars:</Text>
                  </Typography>
                  <SignedupCharacters
                    signedUpCharacters={signedUpCharacters}
                    variant="body2"
                  />
                </Grid>
              )}

              {!isLFS &&
                'EligibleClassesByRole' in offer &&
                offer.EligibleClassesByRole !== undefined && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h5" gutterBottom marginRight={0.5}>
                      <Text color="black">Eligible Classes: </Text>
                    </Typography>
                    <EligibleClasses
                      eligibleClassesByRole={offer.EligibleClassesByRole}
                      totalSpots={offer.TotalSpots}
                      variant="body2"
                      mr={0.5}
                    />
                  </Grid>
                )}

              {offer.Note && (
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom marginRight={0.5}>
                    <Text color="black">Note:</Text>
                  </Typography>
                  {offer.Note}
                </Grid>
              )}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                {' '}
                <Text>{offer._id}</Text>
              </Box>
              <Box>
                <>
                  <CalendarTodayTwoToneIcon
                    fontSize="small"
                    color="secondary"
                    sx={{ marginRight: 0.5 }}
                  />
                  <Text>
                    {new Date(offer.UploadTime * 1000).toDateString()}
                  </Text>
                </>
              </Box>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};
