import { getRegion } from './client';

export const RealmSlugs = {
  US: {
    lightbringer: 'Lightbringer',
    cenarius: 'Cenarius',
    uther: 'Uther',
    kilrogg: 'Kilrogg',
    proudmoore: 'Proudmoore',
    hyjal: 'Hyjal',
    frostwolf: 'Frostwolf',
    nerzhul: "Ner'zhul",
    kiljaeden: "Kil'jaeden",
    blackrock: 'Blackrock',
    tichondrius: 'Tichondrius',
    'silver-hand': 'Silver Hand',
    doomhammer: 'Doomhammer',
    icecrown: 'Icecrown',
    deathwing: 'Deathwing',
    kelthuzad: "Kel'Thuzad",
    eitrigg: 'Eitrigg',
    garona: 'Garona',
    alleria: 'Alleria',
    hellscream: 'Hellscream',
    blackhand: 'Blackhand',
    whisperwind: 'Whisperwind',
    archimonde: 'Archimonde',
    illidan: 'Illidan',
    stormreaver: 'Stormreaver',
    malganis: "Mal'Ganis",
    stormrage: 'Stormrage',
    zuljin: "Zul'jin",
    medivh: 'Medivh',
    durotan: 'Durotan',
    bloodhoof: 'Bloodhoof',
    khadgar: 'Khadgar',
    dalaran: 'Dalaran',
    elune: 'Elune',
    lothar: 'Lothar',
    arthas: 'Arthas',
    mannoroth: 'Mannoroth',
    warsong: 'Warsong',
    'shattered-hand': 'Shattered Hand',
    'bleeding-hollow': 'Bleeding Hollow',
    skullcrusher: 'Skullcrusher',
    'argent-dawn': 'Argent Dawn',
    sargeras: 'Sargeras',
    azgalor: 'Azgalor',
    magtheridon: 'Magtheridon',
    destromath: 'Destromath',
    gorgonnash: 'Gorgonnash',
    dethecus: 'Dethecus',
    spinebreaker: 'Spinebreaker',
    bonechewer: 'Bonechewer',
    dragonmaw: 'Dragonmaw',
    shadowsong: 'Shadowsong',
    silvermoon: 'Silvermoon',
    windrunner: 'Windrunner',
    'cenarion-circle': 'Cenarion Circle',
    nathrezim: 'Nathrezim',
    terenas: 'Terenas',
    'burning-blade': 'Burning Blade',
    gorefiend: 'Gorefiend',
    eredar: 'Eredar',
    shadowmoon: 'Shadowmoon',
    'lightnings-blade': "Lightning's Blade",
    eonar: 'Eonar',
    gilneas: 'Gilneas',
    kargath: 'Kargath',
    llane: 'Llane',
    'earthen-ring': 'Earthen Ring',
    'laughing-skull': 'Laughing Skull',
    'burning-legion': 'Burning Legion',
    thunderlord: 'Thunderlord',
    malygos: 'Malygos',
    thunderhorn: 'Thunderhorn',
    aggramar: 'Aggramar',
    crushridge: 'Crushridge',
    stonemaul: 'Stonemaul',
    daggerspine: 'Daggerspine',
    stormscale: 'Stormscale',
    dunemaul: 'Dunemaul',
    boulderfist: 'Boulderfist',
    suramar: 'Suramar',
    dragonblight: 'Dragonblight',
    draenor: 'Draenor',
    uldum: 'Uldum',
    bronzebeard: 'Bronzebeard',
    feathermoon: 'Feathermoon',
    bloodscalp: 'Bloodscalp',
    darkspear: 'Darkspear',
    azjolnerub: 'Azjol-Nerub',
    perenolde: 'Perenolde',
    eldrethalas: "Eldre'Thalas",
    spirestone: 'Spirestone',
    'shadow-council': 'Shadow Council',
    'scarlet-crusade': 'Scarlet Crusade',
    firetree: 'Firetree',
    frostmane: 'Frostmane',
    gurubashi: 'Gurubashi',
    smolderthorn: 'Smolderthorn',
    skywall: 'Skywall',
    runetotem: 'Runetotem',
    moonrunner: 'Moonrunner',
    detheroc: 'Detheroc',
    kalecgos: 'Kalecgos',
    ursin: 'Ursin',
    'dark-iron': 'Dark Iron',
    greymane: 'Greymane',
    wildhammer: 'Wildhammer',
    staghelm: 'Staghelm',
    'emerald-dream': 'Emerald Dream',
    maelstrom: 'Maelstrom',
    'twisting-nether': 'Twisting Nether',
    chogall: "Cho'gall",
    guldan: "Gul'dan",
    kaelthas: "Kael'thas",
    alexstrasza: 'Alexstrasza',
    'kirin-tor': 'Kirin Tor',
    ravencrest: 'Ravencrest',
    balnazzar: 'Balnazzar',
    azshara: 'Azshara',
    agamaggan: 'Agamaggan',
    lightninghoof: 'Lightninghoof',
    nazjatar: 'Nazjatar',
    malfurion: 'Malfurion',
    aegwynn: 'Aegwynn',
    akama: 'Akama',
    chromaggus: 'Chromaggus',
    draka: 'Draka',
    drakthul: "Drak'thul",
    garithos: 'Garithos',
    hakkar: 'Hakkar',
    'khaz-modan': 'Khaz Modan',
    mugthol: "Mug'thol",
    korgath: 'Korgath',
    'kul-tiras': 'Kul Tiras',
    malorne: 'Malorne',
    rexxar: 'Rexxar',
    'thorium-brotherhood': 'Thorium Brotherhood',
    arathor: 'Arathor',
    madoran: 'Madoran',
    trollbane: 'Trollbane',
    muradin: 'Muradin',
    veknilash: "Vek'nilash",
    senjin: "Sen'jin",
    baelgun: 'Baelgun',
    duskwood: 'Duskwood',
    zuluhed: 'Zuluhed',
    'steamwheedle-cartel': 'Steamwheedle Cartel',
    norgannon: 'Norgannon',
    thrall: 'Thrall',
    anetheron: 'Anetheron',
    turalyon: 'Turalyon',
    haomarush: 'Haomarush',
    scilla: 'Scilla',
    ysondre: 'Ysondre',
    ysera: 'Ysera',
    dentarg: 'Dentarg',
    andorhal: 'Andorhal',
    executus: 'Executus',
    dalvengyr: 'Dalvengyr',
    'black-dragonflight': 'Black Dragonflight',
    'altar-of-storms': 'Altar of Storms',
    uldaman: 'Uldaman',
    'aerie-peak': 'Aerie Peak',
    onyxia: 'Onyxia',
    'demon-soul': 'Demon Soul',
    gnomeregan: 'Gnomeregan',
    anvilmar: 'Anvilmar',
    'the-venture-co': 'The Venture Co',
    sentinels: 'Sentinels',
    jaedenar: 'Jaedenar',
    tanaris: 'Tanaris',
    'alterac-mountains': 'Alterac Mountains',
    undermine: 'Undermine',
    lethon: 'Lethon',
    'blackwing-lair': 'Blackwing Lair',
    arygos: 'Arygos',
    'echo-isles': 'Echo Isles',
    'the-forgotten-coast': 'The Forgotten Coast',
    fenris: 'Fenris',
    anubarak: "Anub'arak",
    'blackwater-raiders': 'Blackwater Raiders',
    vashj: 'Vashj',
    korialstrasz: 'Korialstrasz',
    misha: 'Misha',
    darrowmere: 'Darrowmere',
    ravenholdt: 'Ravenholdt',
    bladefist: 'Bladefist',
    shuhalo: "Shu'halo",
    winterhoof: 'Winterhoof',
    'sisters-of-elune': 'Sisters of Elune',
    maiev: 'Maiev',
    rivendare: 'Rivendare',
    nordrassil: 'Nordrassil',
    tortheldrin: 'Tortheldrin',
    cairne: 'Cairne',
    draktharon: "Drak'Tharon",
    antonidas: 'Antonidas',
    shandris: 'Shandris',
    'moon-guard': 'Moon Guard',
    nazgrel: 'Nazgrel',
    hydraxis: 'Hydraxis',
    'wyrmrest-accord': 'Wyrmrest Accord',
    farstriders: 'Farstriders',
    'borean-tundra': 'Borean Tundra',
    queldorei: "Quel'dorei",
    garrosh: 'Garrosh',
    moknathal: "Mok'Nathal",
    nesingwary: 'Nesingwary',
    drenden: 'Drenden',
    drakkari: 'Drakkari',
    ragnaros: 'Ragnaros',
    quelthalas: "Quel'Thalas",
    azuremyst: 'Azuremyst',
    auchindoun: 'Auchindoun',
    coilfang: 'Coilfang',
    'shattered-halls': 'Shattered Halls',
    'blood-furnace': 'Blood Furnace',
    'the-underbog': 'The Underbog',
    terokkar: 'Terokkar',
    'blades-edge': "Blade's Edge",
    exodar: 'Exodar',
    'area-52': 'Area 52',
    velen: 'Velen',
    'the-scryers': 'The Scryers',
    zangarmarsh: 'Zangarmarsh',
    fizzcrank: 'Fizzcrank',
    ghostlands: 'Ghostlands',
    'grizzly-hills': 'Grizzly Hills',
    galakrond: 'Galakrond',
    dawnbringer: 'Dawnbringer',
    goldrinn: 'Goldrinn',
    nemesis: 'Nemesis',
    azralon: 'Azralon',
    'tol-barad': 'Tol Barad',
    gallywix: 'Gallywix',
    caelestrasz: 'Caelestrasz',
    amanthul: "Aman'Thul",
    barthilas: 'Barthilas',
    thaurissan: 'Thaurissan',
    frostmourne: 'Frostmourne',
    khazgoroth: "Khaz'goroth",
    dreadmaul: 'Dreadmaul',
    nagrand: 'Nagrand',
    dathremar: "Dath'Remar",
    jubeithos: "Jubei'Thos",
    gundrak: 'Gundrak',
    saurfang: 'Saurfang',
    alexsrasza: 'Alexsrasza'
  },
  EU: {
    aggramar: 'Aggramar',
    arathor: 'Arathor',
    aszune: 'Aszune',
    azjolnerub: 'Azjol-Nerub',
    bloodhoof: 'Bloodhoof',
    doomhammer: 'Doomhammer',
    draenor: 'Draenor',
    dragonblight: 'Dragonblight',
    'emerald-dream': 'Emerald Dream',
    garona: 'Garona',
    voljin: "Vol'jin",
    sunstrider: 'Sunstrider',
    arakarahm: 'Arak-arahm',
    'twilights-hammer': "Twilight's Hammer",
    zenedar: 'Zenedar',
    forscherliga: 'Forscherliga',
    medivh: 'Medivh',
    agamaggan: 'Agamaggan',
    alakir: "Al'Akir",
    bladefist: 'Bladefist',
    bloodscalp: 'Bloodscalp',
    'burning-blade': 'Burning Blade',
    'burning-legion': 'Burning Legion',
    crushridge: 'Crushridge',
    daggerspine: 'Daggerspine',
    deathwing: 'Deathwing',
    dragonmaw: 'Dragonmaw',
    dunemaul: 'Dunemaul',
    dethecus: 'Dethecus',
    sinstralis: 'Sinstralis',
    durotan: 'Durotan',
    'argent-dawn': 'Argent Dawn',
    'kirin-tor': 'Kirin Tor',
    dalaran: 'Dalaran',
    archimonde: 'Archimonde',
    elune: 'Elune',
    illidan: 'Illidan',
    hyjal: 'Hyjal',
    kaelthas: "Kael'thas",
    nerzhul: "Ner'zhul",
    chogall: "Cho'gall",
    sargeras: 'Sargeras',
    runetotem: 'Runetotem',
    shadowsong: 'Shadowsong',
    silvermoon: 'Silvermoon',
    stormrage: 'Stormrage',
    terenas: 'Terenas',
    thunderhorn: 'Thunderhorn',
    turalyon: 'Turalyon',
    ravencrest: 'Ravencrest',
    'shattered-hand': 'Shattered Hand',
    skullcrusher: 'Skullcrusher',
    spinebreaker: 'Spinebreaker',
    stormreaver: 'Stormreaver',
    stormscale: 'Stormscale',
    'earthen-ring': 'Earthen Ring',
    alexstrasza: 'Alexstrasza',
    alleria: 'Alleria',
    antonidas: 'Antonidas',
    baelgun: 'Baelgun',
    blackhand: 'Blackhand',
    gilneas: 'Gilneas',
    kargath: 'Kargath',
    khazgoroth: "Khaz'goroth",
    lothar: 'Lothar',
    madmortem: 'Madmortem',
    malfurion: 'Malfurion',
    zuluhed: 'Zuluhed',
    nozdormu: 'Nozdormu',
    perenolde: 'Perenolde',
    'die-silberne-hand': 'Die Silberne Hand',
    aegwynn: 'Aegwynn',
    arthas: 'Arthas',
    azshara: 'Azshara',
    blackmoore: 'Blackmoore',
    blackrock: 'Blackrock',
    destromath: 'Destromath',
    eredar: 'Eredar',
    frostmourne: 'Frostmourne',
    frostwolf: 'Frostwolf',
    gorgonnash: 'Gorgonnash',
    guldan: "Gul'dan",
    kelthuzad: "Kel'Thuzad",
    kiljaeden: "Kil'jaeden",
    malganis: "Mal'Ganis",
    mannoroth: 'Mannoroth',
    'zirkel-des-cenarius': 'Zirkel des Cenarius',
    proudmoore: 'Proudmoore',
    nathrezim: 'Nathrezim',
    'dun-morogh': 'Dun Morogh',
    amanthul: "Aman'Thul",
    senjin: "Sen'jin",
    thrall: 'Thrall',
    theradras: 'Theradras',
    genjuros: 'Genjuros',
    balnazzar: 'Balnazzar',
    anubarak: "Anub'arak",
    wrathbringer: 'Wrathbringer',
    onyxia: 'Onyxia',
    nerathor: "Nera'thor",
    nefarian: 'Nefarian',
    'kult-der-verdammten': 'Kult der Verdammten',
    'das-syndikat': 'Das Syndikat',
    terrordar: 'Terrordar',
    kragjin: "Krag'jin",
    'der-rat-von-dalaran': 'Der Rat von Dalaran',
    nordrassil: 'Nordrassil',
    hellscream: 'Hellscream',
    'laughing-skull': 'Laughing Skull',
    magtheridon: 'Magtheridon',
    quelthalas: "Quel'Thalas",
    neptulon: 'Neptulon',
    'twisting-nether': 'Twisting Nether',
    ragnaros: 'Ragnaros',
    'the-maelstrom': 'The Maelstrom',
    sylvanas: 'Sylvanas',
    vashj: 'Vashj',
    bloodfeather: 'Bloodfeather',
    darksorrow: 'Darksorrow',
    frostwhisper: 'Frostwhisper',
    korgall: "Kor'gall",
    'defias-brotherhood': 'Defias Brotherhood',
    'the-venture-co': 'The Venture Co',
    'lightnings-blade': "Lightning's Blade",
    haomarush: 'Haomarush',
    xavius: 'Xavius',
    'khaz-modan': 'Khaz Modan',
    drekthar: "Drek'Thar",
    rashgarroth: 'Rashgarroth',
    throkferoth: "Throk'Feroth",
    'conseil-des-ombres': 'Conseil des Ombres',
    varimathras: 'Varimathras',
    hakkar: 'Hakkar',
    'les-sentinelles': 'Les Sentinelles',
    khadgar: 'Khadgar',
    bronzebeard: 'Bronzebeard',
    'kul-tiras': 'Kul Tiras',
    chromaggus: 'Chromaggus',
    dentarg: 'Dentarg',
    moonglade: 'Moonglade',
    'la-croisade-écarlate': 'La Croisade écarlate',
    executus: 'Executus',
    trollbane: 'Trollbane',
    mazrigos: 'Mazrigos',
    talnivarr: 'Talnivarr',
    emeriss: 'Emeriss',
    drakthul: "Drak'thul",
    ahnqiraj: "Ahn'Qiraj",
    'scarshield-legion': 'Scarshield Legion',
    ysera: 'Ysera',
    malygos: 'Malygos',
    rexxar: 'Rexxar',
    anetheron: 'Anetheron',
    nazjatar: 'Nazjatar',
    tichondrius: 'Tichondrius',
    'steamwheedle-cartel': 'Steamwheedle Cartel',
    'die-ewige-wacht': 'Die ewige Wacht',
    'die-todeskrallen': 'Die Todeskrallen',
    'die-arguswacht': 'Die Arguswacht',
    uldaman: 'Uldaman',
    eitrigg: 'Eitrigg',
    'confrérie-du-thorium': 'Confrérie du Thorium',
    veknilash: "Vek'nilash",
    boulderfist: 'Boulderfist',
    frostmane: 'Frostmane',
    outland: 'Outland',
    'grim-batol': 'Grim Batol',
    jaedenar: 'Jaedenar',
    kazzak: 'Kazzak',
    'tarren-mill': 'Tarren Mill',
    'chamber-of-aspects': 'Chamber of Aspects',
    ravenholdt: 'Ravenholdt',
    'pozzo-delleternità': "Pozzo dell'Eternità",
    eonar: 'Eonar',
    kilrogg: 'Kilrogg',
    'aerie-peak': 'Aerie Peak',
    wildhammer: 'Wildhammer',
    saurfang: 'Saurfang',
    nemesis: 'Nemesis',
    'darkmoon-faire': 'Darkmoon Faire',
    veklor: "Vek'lor",
    mugthol: "Mug'thol",
    taerar: 'Taerar',
    dalvengyr: 'Dalvengyr',
    rajaxx: 'Rajaxx',
    ulduar: 'Ulduar',
    malorne: 'Malorne',
    'der-abyssische-rat': 'Der abyssische Rat',
    'der-mithrilorden': 'Der Mithrilorden',
    tirion: 'Tirion',
    ambossar: 'Ambossar',
    suramar: 'Suramar',
    krasus: 'Krasus',
    'die-nachtwache': 'Die Nachtwache',
    arathi: 'Arathi',
    ysondre: 'Ysondre',
    eldrethalas: "Eldre'Thalas",
    'culte-de-la-rive-noire': 'Culte de la Rive noire',
    'dun-modr': 'Dun Modr',
    zuljin: "Zul'jin",
    uldum: 'Uldum',
    cthun: "C'Thun",
    sanguino: 'Sanguino',
    shendralar: "Shen'dralar",
    tyrande: 'Tyrande',
    exodar: 'Exodar',
    minahonda: 'Minahonda',
    'los-errantes': 'Los Errantes',
    lightbringer: 'Lightbringer',
    darkspear: 'Darkspear',
    alonsus: 'Alonsus',
    'burning-steppes': 'Burning Steppes',
    'bronze-dragonflight': 'Bronze Dragonflight',
    anachronos: 'Anachronos',
    'colinas-pardas': 'Colinas Pardas',
    ungoro: "Un'Goro",
    garrosh: 'Garrosh',
    'area-52': 'Area 52',
    todeswache: 'Todeswache',
    arygos: 'Arygos',
    teldrassil: 'Teldrassil',
    norgannon: 'Norgannon',
    lordaeron: 'Lordaeron',
    'aggra-português': 'Aggra (Português)',
    terokkar: 'Terokkar',
    'blades-edge': "Blade's Edge",
    azuremyst: 'Azuremyst',
    hellfire: 'Hellfire',
    ghostlands: 'Ghostlands',
    nagrand: 'Nagrand',
    'the-shatar': "The Sha'tar",
    karazhan: 'Karazhan',
    auchindoun: 'Auchindoun',
    'shattered-halls': 'Shattered Halls',
    gordunni: 'Gordunni',
    'lich-king': 'Lich King',
    soulflayer: 'Soulflayer',
    deathguard: 'Deathguard',
    sporeggar: 'Sporeggar',
    nethersturm: 'Nethersturm',
    shattrath: 'Shattrath',
    deepholm: 'Deepholm',
    greymane: 'Greymane',
    'festung-der-stürme': 'Festung der Stürme',
    echsenkessel: 'Echsenkessel',
    blutkessel: 'Blutkessel',
    galakrond: 'Galakrond',
    'howling-fjord': 'Howling Fjord',
    razuvious: 'Razuvious',
    deathweaver: 'Deathweaver',
    'die-aldor': 'Die Aldor',
    'das-konsortium': 'Das Konsortium',
    'chants-éternels': 'Chants éternels',
    'marécage-de-zangar': 'Marécage de Zangar',
    'temple-noir': 'Temple noir',
    fordragon: 'Fordragon',
    naxxramas: 'Naxxramas',
    'borean-tundra': 'Borean Tundra',
    'les-clairvoyants': 'Les Clairvoyants',
    azuregos: 'Azuregos',
    ashenvale: 'Ashenvale',
    'booty-bay': 'Booty Bay',
    eversong: 'Eversong',
    thermaplugg: 'Thermaplugg',
    grom: 'Grom',
    goldrinn: 'Goldrinn',
    blackscar: 'Blackscar'
  }
};

export function getRealms() {
  const region = getRegion();
  if (region) {
    return [...new Set([...Object.values(RealmSlugs[region])])].sort();
  }
  return [
    ...new Set([
      ...Object.values(RealmSlugs.US),
      ...Object.values(RealmSlugs.EU)
    ])
  ].sort();
}

export function slugToRealm(slug: string, region: 'US' | 'EU') {
  return RealmSlugs[region][slug];
}

export function realmToSlug(realm: string, region: 'US' | 'EU') {
  return Object.keys(RealmSlugs[region]).find(
    (key) => RealmSlugs[region][key] === realm
  );
}

export function getSlug(region: 'US' | 'EU', realmOrSlug: string) {
  if (realmOrSlug in RealmSlugs[region]) return realmOrSlug;
  return realmToSlug(realmOrSlug, region);
}

export function getRealm(region: 'US' | 'EU', realmOrSlug: string) {
  if (realmOrSlug in RealmSlugs[region])
    return RealmSlugs[region][realmOrSlug].replace(' ', '');
  return realmOrSlug.replace(' ', '');
}
