import { Middleware } from 'redux';
import { update } from 'src/features/userSlice';
import { fetchWrapper } from 'src/middlewares/updateResponseMiddleware';

const userMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'user/fetch':
        try {
          const data = await fetchWrapper(
            `${process.env.REACT_APP_API_URL}/member`,
            {
              credentials: 'include',
              method: 'GET',
              headers: { 'Content-Type': 'application/json' }
            },
            action,
            storeAPI,
            'Fetched user data',
            'Failed to fetch user data'
          );
          storeAPI.dispatch(update(data.data));
        } catch (error) {
          console.error('Failed to fetch user', error);
        }
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default userMiddleware;
