import { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { getBadgeColor, getUser } from 'src/models/user';
import { useDispatch } from 'react-redux';
import { useUserActivityStatus } from 'src/contexts/UserActivityContext';
import CooldownButton from 'src/components/Utility/CooldownButton';
import Text from 'src/components/Text';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const user = getUser();
  const limitedAccess = !(user?.Characters?.length > 0);
  const dispatch = useDispatch();
  const { userStatus } = useUserActivityStatus();
  const badgeColor = getBadgeColor(
    userStatus === 'active' ? true : userStatus === 'idle' ? false : undefined
  );

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          variant="dot"
          color={badgeColor}
        >
          <Avatar
            variant="rounded"
            alt={user?.DiscordDetails?.Username}
            src={user?.DiscordDetails?.AvatarURL}
          />
        </Badge>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.DiscordDetails?.Username}
            </UserBoxLabel>
            {user?.Karma !== undefined && (
              <UserBoxDescription variant="body2">
                <Text
                  color={
                    user?.Karma < 0
                      ? 'error'
                      : user?.Karma > 100
                      ? 'success'
                      : 'warning'
                  }
                >
                  {user?.Karma} Karma
                </Text>
              </UserBoxDescription>
            )}
            <UserBoxDescription variant="body2">
              {user?.Premium?.Tier === 0 ? 'Free' : 'Premium'}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            variant="dot"
            color={badgeColor}
          >
            <Avatar
              variant="rounded"
              alt={user?.DiscordDetails?.Username}
              src={user?.DiscordDetails?.AvatarURL}
            />
          </Badge>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.DiscordDetails?.Username}
            </UserBoxLabel>
            {user?.Karma !== undefined && (
              <UserBoxDescription variant="body2">
                <Text
                  color={
                    user?.Karma < 0
                      ? 'error'
                      : user?.Karma > 100
                      ? 'success'
                      : 'warning'
                  }
                >
                  {user?.Karma} Karma
                </Text>
              </UserBoxDescription>
            )}
            <UserBoxDescription variant="body2">
              {user?.Premium?.Tier === 0 ? (
                <CooldownButton
                  cooldownSeconds={60}
                  color="secondary"
                  variant="text"
                  sx={{ p: 0 }}
                  onClick={() => {
                    dispatch({
                      type: 'socket/Message/send',
                      payload: {
                        type: 'force_premium_update'
                      }
                    });
                  }}
                >
                  Free User (Wrong? Click)
                </CooldownButton>
              ) : (
                'Premium'
              )}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/dashboards/profile" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>
          {!limitedAccess && (
            <ListItem
              button
              to="/management/profile/settings"
              component={NavLink}
            >
              <AccountTreeTwoToneIcon fontSize="small" />
              <ListItemText primary="Account Settings" />
            </ListItem>
          )}
          {!limitedAccess && (
            <ListItem
              button
              to="/management/profile/notifications"
              component={NavLink}
            >
              <AnnouncementIcon fontSize="small" />
              <ListItemText primary="Notification Settings" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            onClick={() => dispatch({ type: 'login/disconnect' })}
            fullWidth
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
