export interface Character {
  _id: number;
  Name: string;
  Realm: string;
  Server: 'US' | 'EU';
  Class?: string;
  Faction?: 'Horde' | 'Alliance';
  Level: number;
  AverageIlvl?: number;
  MinimumIlvl?: number;
  IconURL?: string;
  Guild?: string;
  Achievements?: number;
  Payment?: boolean;
  LastUpdate?: number;
}

export interface RoleIO {
  Tank?: number;
  Healer?: number;
  DPS?: number;
}

export interface MythicPlusCharacter extends Character {
  RoleIOs: RoleIO;
  SeasonIOs?: { [key: string]: RoleIO };
}

export interface RaidProgression {
  Normal?: number;
  Heroic?: number;
  Mythic?: number;
  Total?: number;
}

export interface RaidDifficulties {
  Normal?: number;
  Heroic?: number;
  Mythic?: number;
}

export interface RaidLastBossKill {
  Normal?: { [key: string]: number };
  Heroic?: { [key: string]: number };
  Mythic?: { [key: string]: number };
}

export interface RaidStats {
  Highest: RaidDifficulties;
}

export interface RaidParse {
  BestAverage: RaidStats;
}

export interface RaidCharacter extends Character {
  RaidProgress: RaidStats;
  RaidParse: RaidParse;
  LastBossKills: RaidLastBossKill;
}

export interface CompleteCharacter extends MythicPlusCharacter {
  RaidProgress?: RaidStats;
  RaidParse?: RaidParse;
  LastBossKills?: RaidLastBossKill;
}

export interface SignedUpCharacter extends CompleteCharacter {
  Key?: string;
  KeyLevel?: number;
  MinimumKeyLevel?: number;
}

export function ParseColor(parse: number): string {
  if (parse == 100) {
    return '#e5cc80';
  } else if (parse == 99) {
    return '#f48cba';
  } else if (parse >= 95) {
    return '#ff8000';
  } else if (parse >= 75) {
    return '#a335ee';
  } else if (parse >= 50) {
    return '#0070dd';
  } else if (parse >= 25) {
    return '#1eff00';
  } else {
    return '#9d9d9d';
  }
}

export class CharacterObj {
  character: Character | MythicPlusCharacter | RaidCharacter;

  constructor(character: Character) {
    this.character = character;
  }

  isMythicPlusCharacter(): boolean {
    return (this.character as MythicPlusCharacter).RoleIOs !== undefined;
  }

  isRaidCharacter(): boolean {
    return (this.character as RaidCharacter).RaidProgress !== undefined;
  }

  getArmoryURL(): URL {
    const regionHost = () => {
      switch (this.character.Server) {
        case 'US':
          return 'en-US';
        case 'EU':
          return 'en-GB';
      }
    };
    return new URL(
      'https://worldofwarcraft.com/' +
        regionHost() +
        '/character/' +
        this.character.Realm +
        '/' +
        this.character.Name
    );
  }

  getWarcraftLogsURL(): URL {
    return new URL(
      'https://www.warcraftlogs.com/character/' +
        this.character.Server +
        '/' +
        this.character.Realm +
        '/' +
        this.character.Name
    );
  }

  getRaiderIOURL(): URL {
    return new URL(
      'https://raider.io/characters/' +
        this.character.Server +
        '/' +
        this.character.Realm +
        '/' +
        this.character.Name
    );
  }

  getHighestIO(season?: string): [string, number] {
    if (this.isMythicPlusCharacter()) {
      const character = this.character as MythicPlusCharacter;
      let roleIOs: RoleIO;
      if (season) {
        if (character.SeasonIOs && character.SeasonIOs[season])
          roleIOs = character.SeasonIOs[season];
        else return ['', 0];
      } else {
        roleIOs = character.RoleIOs;
      }
      let highestIO = 0;
      let highestRole = '';
      for (const role in roleIOs) {
        let roleIO = roleIOs[role as keyof RoleIO];
        if (roleIO !== undefined && roleIO > highestIO) {
          highestIO = roleIO;
          highestRole = role;
        }
      }
      return [highestRole, highestIO];
    }
    return ['', 0];
  }

  getRaidProgressionString(): [string, number] {
    if (this.isRaidCharacter()) {
      const character = this.character as RaidCharacter;
      const raidProgression = character.RaidProgress;
      if (
        raidProgression.Highest.Mythic &&
        raidProgression.Highest.Mythic > 0
      ) {
        return ['Mythic', raidProgression.Highest.Mythic];
      }
      if (
        raidProgression.Highest.Heroic &&
        raidProgression.Highest.Heroic > 0
      ) {
        return ['Heroic', raidProgression.Highest.Heroic];
      }
      if (
        raidProgression.Highest.Normal !== undefined &&
        raidProgression.Highest.Normal >= 0
      ) {
        return ['Normal', raidProgression.Highest.Normal];
      }
    }
    return ['', 0];
  }
}

export function getCharacter() {
  return {
    _id: 222594956,
    Name: 'Lockiman',
    Realm: 'Illidan',
    Server: 'US',
    Class: 'Warlock',
    Faction: 'Horde',
    Payment: true,
    Level: 60,
    AverageIlvl: 450,
    MinimumIlvl: 447,
    Achievements: 10000,
    IconURL:
      'https://render.worldofwarcraft.com/us/character/illidan/140/222594956-avatar.jpg?alt=wow/static/images/2d/avatar/2-0.jpg',
    Guild: 'Melee Mechanics',
    RoleIOs: {
      Tank: 1,
      Healer: 100,
      DPS: 3200
    },
    RaidProgress: {
      Highest: {
        Normal: 9,
        Heroic: 9,
        Mythic: 9
      }
    },
    RaidParse: {
      BestAverage: {
        Highest: {
          Normal: 100,
          Heroic: 99,
          Mythic: 95
        }
      }
    }
  };
}

export function UpdateChar(charInstance, setIsUpdating?) {
  if (setIsUpdating) setIsUpdating(true);
  fetch(`https://wowlfg.com/api/characters/update`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      charid: charInstance.character._id
    })
  })
    .then((res) => res.json())
    .then((data) => {
      if (setIsUpdating) setIsUpdating(false);
    });
}

export function SortByRealm(characters: Character[]): {
  [key: string]: { [key: number]: Character };
} {
  let sortedCharacters: { [key: string]: { [key: number]: Character } } = {};
  characters.forEach((character) => {
    if (character.Payment === undefined) return;
    if (!sortedCharacters[character.Realm]) {
      sortedCharacters[character.Realm] = {};
    }
    sortedCharacters[character.Realm][character._id] = character;
  });
  return sortedCharacters;
}
